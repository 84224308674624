import axios from 'axios';
import { camelizeKeys, decamelizeKeys } from 'humps';

/* UTILS */
import RestActions from 'utils/rest/actions';
import serialize from 'utils/rest/serialize';

/* PATH */
import {
  getClaimDocumentsPath,
  documentsPathSecond,
  documentsMetadataPath,
  generateAttestivReportPath,
} from 'utils/paths';
// import { generateAttestivReport } from 'actions/claim';

/* TYPES */
import {
  CLEAR_DOCUMENT_LIST,
  UPLOADED_FILE,
  SET_FILE_SOURCE,
  CLEAR_MESSAGE_POPUP,
  REMOVE_ATTACHEMENT_FILE,
  CLEAR_DOCUMENT_ERRORS,
  SHOW_SUCCESS_MESSAGE,
  HIDE_SUCCESS_MESSAGE,
  DISABLE_UPLOAD,
  ENABLE_UPLOAD,
  SHOW_PROGRESS,
  HIDE_PROGRESS,
} from './types';

import { permittedTmpDomains } from 'utils/constants';

const restClaimDocumentActions = new RestActions('claimDocument');

const {
  fetchAllStart,
  fetchAllFinished,
  createStart,
  createFinished,
  deleteStart,
  deleteFinished,
} = restClaimDocumentActions;

/* ACTIONS */
export const fetchClaimDocuments = (id, params = {}, isCustomer = false) => {
  return async (dispatch) => {
    dispatch(fetchAllStart());
    const path = isCustomer
      ? `${getClaimDocumentsPath(id)}${serialize(
          params,
          true
        )}&without_third_party=Union Member`
      : `${getClaimDocumentsPath(id)}${serialize(params, true)}`;
    const docXFile =
      'vnd.openxmlformats-officedocument.wordprocessingml.document';
    await axios.get(path).then(
      ({ data }) => {
        const formattedDocuments = data.documents.map((doc) => {
          const contentType = doc?.content_type?.includes(docXFile)
            ? 'DOCX'
            : doc?.content_type?.split(/(\b\/\b)(?!.*\1)/)[2].toUpperCase();
          return {
            ...doc,
            fullUrl: doc.url?.original ?? doc.url?.large ?? doc.url?.medium,
            filename:
              doc.filename.split(/((\B|\b)+\.(\B|\b)+)(?!.*\1)/)[0] ||
              doc.filename,
            contentType: contentType ?? '',
          };
        });
        formattedDocuments.sort(function (a, b) {
          return new Date(b.created_at) - new Date(a.created_at);
        });
        const res = {
          ...data,
          documents: formattedDocuments,
        };
        dispatch(fetchAllFinished(camelizeKeys(res)));
      },
      (error) => {
        dispatch(fetchAllFinished(error));
      }
    );
  };
};

export const uploadDocument =
  (id, doc, isUnionMember = false, { fingerprint = '', type = '' }) =>
  async (dispatch) => {
    dispatch(createStart());
    return await axios.post(documentsPathSecond, decamelizeKeys(doc)).then(
      async ({ data }) => {
        const { document } = data;
        dispatch(createFinished(document));

        // ATTESTIV REPORT
        // if (type === 'image/jpg' || type === 'image/jpeg') {
        //   const metadata = {
        //     documentId: document.id,
        //     timestamp: Date.now() / 1000,
        //     fingerprint: fingerprint,
        //   };
        //   await axios
        //     .post(documentsMetadataPath, decamelizeKeys(metadata))
        //     .then(
        //       async () => {
        //         await axios.post(generateAttestivReportPath(id));
        //       },
        //       (error) => {
        //         console.log(error);
        //       }
        //     );
        // }

        if (!isUnionMember) {
          await dispatch(fetchClaimDocuments(id));
        }
      },
      (error) => {
        dispatch(createFinished(error));
      }
    );
  };

export const clearDocumentList = () => (dispatch) => {
  dispatch({
    type: CLEAR_DOCUMENT_LIST,
  });
};

export const messagePopupUploadData = (fileData) => (dispatch) => {
  dispatch({
    type: UPLOADED_FILE,
    payload: fileData,
  });
};

export const setFileSource = (fileSource) => (dispatch) => {
  dispatch({
    type: SET_FILE_SOURCE,
    payload: fileSource,
  });
};

export const showSuccessMessage = () => (dispatch) => {
  dispatch({
    type: SHOW_SUCCESS_MESSAGE,
  });
};

export const hideSuccessMessage = () => (dispatch) => {
  dispatch({
    type: HIDE_SUCCESS_MESSAGE,
  });
};

export const clearMessagePopup =
  (attachments = [], showSuccess = false) =>
  (dispatch) => {
    if (attachments && attachments.length > 0) {
      if (showSuccess) {
        dispatch({
          type: CLEAR_MESSAGE_POPUP,
        });
        dispatch({
          type: CLEAR_DOCUMENT_ERRORS,
        });
        hideSuccessMessage();
      } else {
        const deleteAttacment = (id) =>
          new Promise((resolve) => {
            let attachmentPath = `${documentsPathSecond}/${id}`;
            axios.delete(attachmentPath).then(() => resolve());
          });
        const ids = attachments.map((att) => att.id);
        const requests = ids.map((id) => deleteAttacment(id));
        return Promise.all([...requests]).then(() => {
          dispatch({
            type: CLEAR_MESSAGE_POPUP,
          });
          dispatch({
            type: CLEAR_DOCUMENT_ERRORS,
          });
        });
      }
    } else {
      dispatch({
        type: CLEAR_MESSAGE_POPUP,
      });
      dispatch({
        type: CLEAR_DOCUMENT_ERRORS,
      });
    }
  };

export const removeAttachementsFile = (attachementId) => (dispatch) => {
  const path = `${documentsPathSecond}/${attachementId}`;
  dispatch(deleteStart());
  axios.delete(path).then(
    () => {
      dispatch({
        type: REMOVE_ATTACHEMENT_FILE,
        payload: attachementId,
      });
    },
    (err) => {
      dispatch(deleteFinished(err));
    }
  );
};

export const uploadDocumentMessage =
  (id, doc, prevState, isUnionMember = false) =>
  (dispatch) => {
    dispatch(createStart());
    dispatch({
      type: DISABLE_UPLOAD,
    });
    dispatch({
      type: SHOW_PROGRESS,
    });
    return axios.post(documentsPathSecond, decamelizeKeys(doc)).then(
      (response) => {
        const { data } = response;
        const { document } = data;
        const { filename, id: documentId, content_type } = document;

        const { fields } = prevState;
        const {
          subject,
          text,
          claim,
          attachments,
          tmpEmailExtension = permittedTmpDomains[0].value,
          tmpEmailName = '',
        } = fields;
        const updatedAttachments = [
          ...attachments,
          {
            content: doc.file,
            filename,
            id: documentId,
            type: content_type,
          },
        ];

        if (isUnionMember) {
          dispatch(
            messagePopupUploadData({
              formData: {
                subject,
                text,
                claim,
                attachments: updatedAttachments,
                tmpEmailExtension,
                tmpEmailName,
              },
            })
          );
          dispatch({
            type: ENABLE_UPLOAD,
          });
          dispatch({
            type: HIDE_PROGRESS,
          });
        } else {
          dispatch(
            messagePopupUploadData({
              formData: {
                subject,
                text,
                claim,
                attachments: updatedAttachments,
              },
            })
          );
          dispatch({
            type: ENABLE_UPLOAD,
          });
          dispatch({
            type: HIDE_PROGRESS,
          });
        }

        dispatch(createFinished(data));
        if (!isUnionMember) {
          dispatch(fetchClaimDocuments(id));
        }
        return updatedAttachments;
      },
      (error) => {
        dispatch({
          type: ENABLE_UPLOAD,
        });
        dispatch({
          type: HIDE_PROGRESS,
        });
        dispatch(createFinished(error));
      }
    );
  };

export const clearClaimDocumentsErrors = () => (dispatch) => {
  dispatch({
    type: CLEAR_DOCUMENT_ERRORS,
  });
};

export const sendDocumentMetedata = (metadata) => async (dispatch) => {
  axios.post(documentsMetadataPath, decamelizeKeys(metadata)).then(
    () => {},
    (error) => {}
  );
};

export const generateAttestivReport = (id) => (dispatch) => {
  axios.post(generateAttestivReportPath(id)).then(
    () => {},
    (error) => {}
  );
};
